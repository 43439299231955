/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import axios from "axios";
import LCFQheader from "../../LCFQheader";
import MbhzCheck from "../../../common/hjkzmb/MbhzCheck";
import MbhzForm from "../../../common/hjkzmb/MbhzForm";
import { RxUtil } from "@/assets/util.js";
import { timesFnt, unique, uniqueRypxz, format, formatHMT } from "@/assets/app.js";
export default {
  name: "lcfqMbhzjl",
  components: {
    LCFQheader,
    MbhzCheck,
    MbhzForm
  },
  data() {
    return {
      oneft: "/index",
      headTit: "新建申请",
      tit: "环境控制目标汇总申请",
      //userSelect
      single: false,
      allowSelect: true,
      showUserDialog: false,
      showDataSJ: false,
      showDataCG: false,
      showsyqck: false,
      minDate: new Date(2020, 0, 1),
      currentDate: new Date(),
      showListpage: false,
      showGinfo: false,
      userID: "",
      solId: "",
      actDefId: "",
      boDefId: "",
      formKey: "",
      resultFa: [],
      dataListIndex: [],
      tremListId: [],
      dataInfo: {},
      showBntData: "显示",
      indexNum: "",
      datas: {
        hzr: ''
      },
      bzrVal: "",
      mcVal: "",
      cgsqrT: new Date(),
      totalList: []
    };
  },
  created() {
    this.initBySolInstId();
    this.cgORxj();
  },
  methods: {
    initBySolInstId() {
      let solId = this.$route.params.solId;
      let instId = this.$route.params.instId;
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId;
      this.$ajax.post(url).then(response => {
        this.solId = response.data.bpmSolution.solId;
        this.actDefId = response.data.bpmSolution.actDefId;
        this.boDefId = response.data.formModels[0].boDefId;
        this.formKey = response.data.formModels[0].formKey;
        if (instId == 0) {
          let jdy = response.data.formModels[0].jsonData.hzr;
          let jdy_name = response.data.formModels[0].jsonData.hzr_name;
          let jdyObj = [{
            id: jdy,
            text: jdy_name
          }];
          this.datas.hzr = JSON.stringify(jdyObj);
        }
      });
    },
    async cgORxj() {
      let instId = this.$route.params.instId;
      if (instId != 0) {
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";
        /**测试接口**/
        var user_No = RxUtil.getCache("userNo"); //这里要重新储存
        console.log(user_No);
        var url2 = _baseUrl + "/mobile/bpm/getFormDataByInstId.do?instId=" + instId + "&userAccount=" + user_No; //+"&instId="+instId
        var this_ = this;
        this.$ajax.post(url2).then(response => {
          var resData = response.data.data.bos[0].data;
          console.log("草稿数据返填=", resData);
          this_.datas.hzr = this.setSelectData(resData.hzr, resData.hzr_name);
          this_.datas.hzsj = resData.hzsj;
          resData.SUB_HJKZMBHZJL.map((item, index) => {
            var addObj = {};
            addObj.F_BZR_NAME = item.bzr;
            addObj.F_SQSJ = item.sqsj;
            addObj.F_JCSMC = item.jcsmc;
            addObj.F_BM_NAME = item.bm;
            addObj.F_WD = item.wd;
            addObj.F_SD = item.sd;
            addObj.F_QY = item.qy;
            addObj.F_TFPQ_NAME = item.tfpq;
            addObj.F_ZD = item.zd;
            addObj.F_SFCL = item.sfcl;
            addObj.F_QTTJ = item.qttj;
            addObj.F_ZRR_NAME = item.zrr;
            addObj.F_BZ = item.bz;
            this_.dataListIndex.push(addObj);
          });
        });
        /***/
        //     var url = _baseUrl + "/customizeProc/purRequisition/purRequisition/getJson.do?ids="+instId;
        // this.$ajax.post(url).then(res=>{
        // 	this.data = res.data;
        // })
      } else {}
    },
    setSelectData(strId, strName) {
      let zzzObj = [{
        id: strId,
        text: strName
      }];
      return JSON.stringify(zzzObj);
    },
    //选代理人
    selectUserDialog(vm) {
      this.showUserDialog = true;
      this.$refs.sysUser.search();
      // 设置选择用户。
      this.$refs.sysUser.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    selectUser(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        o.id = obj.userId;
        o.text = obj.fullname;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeUserVm.setValue(json);
      this.showUserDialog = false;
    },
    onConfirmSJ(timestamp) {
      this.datas.hzsj = format(timestamp);
      this.showDataSJ = false;
    },
    //添加列表项目
    addTerm() {
      this.tremListId = [];
      this.dataListIndex.map(item => {
        this.tremListId.push(item.ID_);
      });
      this.showListpage = true;
    },
    tremList(list) {
      let listIndex = [];
      this.dataListIndex.map(item => {
        listIndex.push(item);
      });
      list.map(item => {
        listIndex.push(item);
      });
      /**新增重复问题解决，过滤掉同对象*/
      var obj = {};
      this.dataListIndex = listIndex.reduce(function (item, next) {
        obj[next.ID_] ? '' : obj[next.ID_] = true && item.push(next);
        return item;
      }, []);
      this.showListpage = false;
    },
    bzrName(value) {
      this.bzrVal = value;
    },
    mcName(value) {
      this.mcVal = value;
    },
    addTermContent() {
      let addData = {
        F_BZR_NAME: "",
        F_SQSJ: "",
        F_JCSMC: "",
        F_BM_NAME: "",
        F_WD: "",
        F_SD: "",
        F_QY: "",
        F_TFPQ_NAME: "",
        F_ZD: "",
        F_SFCL: "",
        F_QTTJ: "",
        F_ZRR_NAME: "",
        F_BZ: "",
        INST_STATUS_: ""
      };
      let rq = new Date();
      addData.F_SQSJ = format(rq);
      this.dataListIndex.push(addData);
    },
    //查看具体信息
    goodsInfo(item) {
      this.showGinfo = true;
      this.dataInfo = item;
    },
    //完善信息
    tremListInfo(item, index) {
      this.indexNum = index;
      this.dataInfo = item;
      this.showGinfo = false;
    },
    //删除
    delectData() {
      let desc = function (a, b) {
        return b - a;
      };
      this.resultFa.sort(desc);
      for (var i = 0; i < this.resultFa.length; i++) {
        this.dataListIndex.splice(this.resultFa[i], 1);
      }
      this.$refs.checkboxIndex.toggleAll(false);
    },
    async upDatas() {
      let totalList = [];
      let formData = new FormData();
      formData.append("Q_F_BZR_NAME_S_LK", this.bzrVal);
      formData.append("Q_F_JCSMC_S_LK", this.mcVal);
      let url = _baseUrl + `/dev/cus/customData/listHjKzMbSqJl/getData.do`;
      await this.$ajax.post(url, formData).then(res => {
        totalList = res.data.data;
      });
      totalList.map((item, index) => {
        item._id = index + 1;
        item._uid = index + 1;
      });
      let addObj = {};
      let addedList = [];
      this.dataListIndex.map((item, index) => {
        addObj = {};
        addObj.bzr = item.F_BZR_NAME;
        addObj.sqsj = item.F_SQSJ;
        addObj.jcsmc = item.F_JCSMC;
        addObj.bm = item.F_BM_NAME;
        addObj.wd = item.F_WD;
        addObj.sd = item.F_SD;
        addObj.qy = item.F_QY;
        addObj.tfpq = item.F_TFPQ_NAME;
        addObj.zd = item.F_ZD;
        addObj.sfcl = item.F_SFCL;
        addObj.qttj = item.F_QTTJ;
        addObj.zrr = item.F_ZRR_NAME;
        addObj.bz = item.F_BZ;
        addObj._id = totalList.length + index + 1;
        addObj._uid = totalList.length + index + 1;
        addObj._state = "added";
        addedList.push(addObj);
      });
      let hzr = JSON.parse(this.datas.hzr);
      var jsonDataObj = {
        bos: [{
          boDefId: this.boDefId,
          formKey: this.formKey,
          data: {
            hzr: hzr[0].id,
            hzr_name: hzr[0].text,
            hzsj: this.datas.hzsj,
            Q_F_BZR_NAME_S_LK: this.bzrVal,
            Q_F_JCSMC_S_LK: this.mcVal,
            SUB_listHjKzMbSqJlGrid: totalList,
            SUB_HJKZMBHZJL: addedList
          }
        }]
      };
      var jsonData = JSON.stringify(jsonDataObj);
      var params = new FormData();
      params.append("solId", this.solId);
      params.append("bpmInstId", "");
      params.append("jsonData", jsonData);
      return params;
    },
    //字段检查
    iN(str) {
      if (str == '' || str == null || typeof str == 'undefined') {
        return true;
      }
      return false;
    },
    //整体判断
    inspect() {
      if (this.iN(this.datas.hzr) || this.iN(this.datas.hzsj)) {
        return true;
      }
      return false;
    },
    async onSubmit() {
      if (this.inspect()) {
        this.errorToast("启动失败！请检查信息是否填写完整", "1800");
        return;
      }
      if (this.dataListIndex.length == 0) {
        this.errorToast("启动失败！请至少添加一条计划", "1800");
        return;
      }
      var params = await this.upDatas();
      var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
      this.$dialog.loading.open('正在启动流程,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("启动成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败！请检查信息是否填写完整", "1800");
        }
      });
    },
    async onPreservation() {
      if (this.inspect()) {
        this.errorToast("保存失败！请检查信息是否填写完整", "1800");
        return;
      }
      var params = await this.upDatas();
      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败！请检查信息是否填写完整", "1800");
        }
      });
    },
    onLct() {
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    }
  }
};