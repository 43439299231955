/* this file is transformed by vux-loader */
/* eslint-disable */
import { format, keepTwoDecimalFull } from "../../../assets/app.js";
export default {
  props: {
    dataInfo: {
      default: {}
    },
    showBntData: {
      default: ""
    }
  },
  data() {
    return {
      showDataSSJH: false,
      currentDate: new Date(),
      showBnt: false,
      showZT: true
    };
  },
  created() {
    this.dataInfo.F_SQSJ = format(this.dataInfo.F_SQSJ);
    if (this.showBntData == "显示") {
      this.showBnt = true;
      this.showZT = false;
    }
  },
  methods: {
    onConfirmSSJH(timestamp) {
      this.dataInfo.F_SQSJ = format(timestamp);
      this.showDataSSJH = false;
    },
    onProps() {
      this.datas = this.dataInfo;
      this.$emit("tremListInfo", this.datas);
    }
  }
};