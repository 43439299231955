/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
// import { getTestTable } from "../../api/index";

import { unique } from "@/assets/app.js";
import MbhzForm from "./MbhzForm";
import { Dialog } from 'vant';
export default {
  components: {
    MbhzForm
  },
  props: {
    tremListId: {
      default: []
    }
  },
  data() {
    return {
      //搜索
      searchValmc: "",
      searchValbzr: "",
      showInfo: false,
      ListId: [],
      dataList: [],
      mount: [],
      mountDupRe: [],
      pageNum: 1,
      prePage: 1,
      maxPageNum: 1,
      totalNum: 0,
      // pageIndex: 0,
      pageSize: 20,
      result: [],
      itemString: "",
      dataInfo: {}
    };
  },
  created() {
    this.getCgwpInfo();
  },
  computed: {
    ListIdNew() {
      let ListId = [];
      this.tremListId.map(ID_ => {
        if (ListId.indexOf(ID_) == -1) {
          ListId.push(ID_);
        }
      });
      return ListId; //watch监听要求
    }
  },

  watch: {
    ListIdNew: function (newid) {
      this.$refs.checkboxGroup.toggleAll();
      this.result = newid;
    }
  },
  methods: {
    getCgwpInfo() {
      var pageNum = this.pageNum - 1;
      var formData = new FormData();
      formData.append("pageIndex", pageNum);
      formData.append("pageSize", this.pageSize);
      if (this.searchValbzr != "" || this.searchValmc != "") {
        formData.append("Q_F_BZR_NAME_S_LK", this.searchValbzr);
        formData.append("Q_F_JCSMC_S_LK", this.searchValmc);
      }
      var url = _baseUrl + `/dev/cus/customData/listHjKzMbSqJl/getData.do`;
      this.$ajax.post(url, formData).then(res => {
        this.dataList = res.data.data;
        this.dataList.map(item => {
          if (JSON.stringify(this.mount).indexOf(item.ID_) == -1) {
            this.mount.push(item);
          }
        });
        this.totalNum = res.data.total;
        this.maxPageNum = Math.ceil(this.totalNum / 20);
      });
    },
    onPlus() {
      this.pageNum += 1;
      this.getCgwpInfo();
    },
    onMinus() {
      this.pageNum -= 1;
      this.getCgwpInfo();
    },
    onChange() {
      var pageNum = this.pageNum;
      var maxPageNum = this.maxPageNum;
      if (pageNum <= maxPageNum) {
        // this.prePage = pageNum;
        this.getCgwpInfo();
      } else {
        Dialog({
          message: '请正确输入页数！'
        });
      }
    },
    goodsInfo(item) {
      this.showInfo = true;
      item.INST_STATUS_ = item.INST_STATUS_.substring(53, 57);
      this.dataInfo = item;
    },
    //搜索、清除
    onSearch() {
      this.pageNum = 1;
      this.getCgwpInfo();
    },
    onClear() {
      this.searchValmc = "";
      this.searchValbzr = "";
      this.pageNum = 1;
      this.getCgwpInfo();
    },
    //确定
    onSelects() {
      let result = [];
      this.mount.map(item => {
        this.result.map(pkid => {
          if (pkid == item.ID_) {
            result.push(item);
          }
        });
      });
      this.$emit("tremList", result);
      this.$emit("bzrName", this.searchValbzr);
      this.$emit("mcName", this.searchValmc);
    }
  }
};